import React, { useContext, useState } from "react";
import MainLayout from "../layouts/mainLayout";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import { Box, Grid, Container, Typography, Button } from "@material-ui/core";
import SEO from "../components/seo";
import Section from "../components/section";
import Carousel from "nuka-carousel";
import { ShopContext } from "../context/shop-context-provider";
import LinkButton from "../components/link-button";
import styled from "@emotion/styled";
import MailchimpSubscribe from "../components/mailchimp-subscribe";

export const pageQuery = graphql`
  query($handle: String!) {
    shopifyProduct(handle: { eq: $handle }) {
      id
      title
      handle
      productType
      description
      descriptionHtml
      shopifyId
      options {
        id
        name
        values
      }
      variants {
        id
        title
        price
        compareAtPrice
        availableForSale
        shopifyId
        selectedOptions {
          name
          value
        }
      }
      priceRange {
        minVariantPrice {
          amount
          currencyCode
        }
        maxVariantPrice {
          amount
          currencyCode
        }
      }
      images {
        originalSrc
        id
        localFile {
          childImageSharp {
            fluid(maxHeight: 600, maxWidth: 600) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`


interface ProductPageProps {
  location: Location,
  data: {
    shopifyProduct
  },
  pageContext: {

  }
}

interface ProductVariantPrice {
  price: string;
  compareAtPrice: string;
  shopifyId: string;
}

interface ProductListing {
  handle: string;
  title: string;
  variants: ReadonlyArray<ProductVariantPrice>;
  images: [{
    localFile: import("gatsby-source-filesystem").FileSystemNode;
  }]
}

const ListPrice = styled(Typography)`
    padding: 0px 8px;
    text-decoration: line-through;
`;

const ProductPageDisplay: React.FC<ProductListing> = (props) => {
  const { adding, checkout, addToCart } = useContext(ShopContext);
  const [variant, setVariant] = useState("");

  let activeVariant = null;

  if (variant == "") {
    var min = props.variants.reduce((prev, curr) => {
      if (!prev)
        curr;

      if (curr.price < prev.price)
        return curr;

      return prev;
    });

    setVariant(min.shopifyId);
    activeVariant = min;
  }
  else {
    activeVariant = props.variants.find(f => f.shopifyId == variant);
  }

  const renderImages = () => {
    if (props.images.length == 1) {
      return <Box>
        <Img fluid={(props.images[0].localFile.childImageSharp as any).fluid} />
      </Box>
    } else {
      return <Carousel wrapAround autoplay={false}>
          {props.images.map((i, idx) => <Img fluid={(i.localFile.childImageSharp as any).fluid} key={idx} />)}
      </Carousel>
    }
  }

  var inCart = checkout.lineItems.some(x => x.variant.id == activeVariant.shopifyId);
  var outOfStock = true;

  const renderButton = () => {
    if (outOfStock) {
      return <Button variant="contained" color="primary" disabled={true} onClick={() => addToCart(activeVariant.shopifyId, 1)}>Sold Out</Button>;
    } else if (inCart) {
      return <LinkButton url="/cart" color="primary" variant="outlined">View In Cart</LinkButton>
    } else {
      return <Button variant="contained" color="primary" disabled={adding} onClick={() => addToCart(activeVariant.shopifyId, 1)}>Add To Cart</Button>;
    }
  }

  const getDiscount = (props: ProductVariantPrice) => {
    var percentOff = 1 - (parseFloat(activeVariant.price) / parseFloat(activeVariant.compareAtPrice));
    return Math.round(percentOff * 100);
  }

  return <Section noBottom>
    <Container>
      <Grid container alignItems="center">
        <Grid item xs={12} sm={6}>
          <Box p={1}>
            {renderImages()}
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box p={1} display="flex" flexDirection="column" justifyContent="center">
            <Box pt={1} minHeight={108} display="flex" justifyContent="center" textAlign="center">
              <Typography align="center" component="h1" variant="h2">{props.title}</Typography>
            </Box>
            <Box width="100%" textAlign="center">
              <Typography variant="h6" component="span" color="primary">${activeVariant.price}</Typography>
              {activeVariant.compareAtPrice && <>
                <ListPrice variant="h6" component="span">${activeVariant.compareAtPrice}</ListPrice>
                <Typography variant="h6" component="span">({getDiscount(activeVariant)}% off)</Typography>
              </>
              }
            </Box>
            <Box pt={3} pb={2} textAlign="center">
              {renderButton()}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  </Section>

}

const ProductPage: React.FC<ProductPageProps> = (props) => {
  const { shopifyProduct } = props.data;
  return <MainLayout>
    <SEO location={props.location} title={`Shop ${shopifyProduct.title}`} description={shopifyProduct.description} />

    <ProductPageDisplay {...shopifyProduct} />

    <Section tightTop>
      <Container maxWidth="md">
        <Typography gutterBottom variant="h3">Out Of Stock</Typography>
        <Typography variant="body1">
          We are experiencing unexpected delays in the manufacturing of our bottles and unique nasal sprayers. You can sign up for our mailing list to be notified when the product is back in stock.
        </Typography>
        <Box py={4}>
        <MailchimpSubscribe cta="Get Notified" />
        </Box>
        <Typography gutterBottom variant="h3" component="h2">Description</Typography>
        <Typography variant="body1"> 
          {shopifyProduct.description}
        </Typography>
      </Container>
    </Section>

  </MainLayout>;
}

export default ProductPage;